body {
  margin: 0;
  font-family: HumanSans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Workaround for changing dots color */
  /* https://github.com/akiran/react-slick/issues/2046 */
  .slick-dots li button:before {
    opacity: 1; /* default is 0.25 */
    color: #ffffff;
    font-size: 12px;
  }
  .slick-dots li.slick-active button:before {
    color: #ea2e01;
    opacity: 1; /* default is 0.75 */
  }

  .slick-prev {
    left: 50px;
    z-index: 10;
  }

  .slick-next {
    right: 50px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 
.slider-wrapper {
  width: 100% !important;
} */

/* HumanSans Font */
@font-face {
  font-family: "HumanSans";
  src: url("./fonts/HumanSans-Bold.otf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "HumanSans";
  src: url("./fonts/HumanSans-Light.otf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "HumanSans";
  src: url("./fonts/HumanSans-Medium.otf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "HumanSans";
  src: url("./fonts/HumanSans-Regular.otf") format("truetype");
  font-weight: 400;
}

.slider-wrapper {
  width: 85% !important;
}
