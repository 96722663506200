.container {
  height: 10vh;
  width: 100vw;
  background-color: #392f2e;
  align-items: center;
}

.grid {
  width: 100vw;
  padding: 16px 32px;
  align-items: center;
}
.navSection {
  justify-content: space-between;
}

.logoImg {
  height: 27px;
}

.navText {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.022em;
  text-align: center;
  color: #ffffff;
}

.hamburgerIcon {
  display: none !important;
}

.mobile {
  width: 100vw;
  height: auto;
  position: fixed;
  flex-direction: column;
  background-color: #f0f0f0;
}

.navTextMobile {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.022em;
  text-align: center;
}

.contactButton {
  width: 151px;
  padding: 10px 24px;
  border-radius: 30px !important;
  font-weight: 700 !important;
}

.mobile {
  width: 100vw;
  height: 60vh;
  position: fixed;
  overflow: auto;
  flex-direction: column;
}

@media screen and (min-width: 1920px) {
  .logoImg {
    height: 2.5rem;
  }

  .navText {
    font-size: 1.25rem;
  }

  .contactButton {
    font-size: 1.2rem !important;
    line-height: 24px !important;
    padding: 12px 24px !important;
    height: auto !important;
    width: auto;
  }
}

@media screen and (max-width: 1024px) {
  .logoImg {
    height: 1.5rem;
  }
}

@media screen and (max-width: 820px) {
  .logoImg {
    height: 1.25rem;
  }
  .navText {
    font-size: 0.75rem;
  }

  .contactButton {
    font-size: 0.75rem !important;
    width: 14vw !important;
  }
}

@media screen and (max-width: 760px) {
  .logoImg {
    height: 1.5rem;
  }

  .logoGrid {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .navSection {
    display: none !important;
  }

  .contactButton {
    display: none !important;
  }

  .hamburgerIcon {
    display: block !important;
  }
}
