.container {
  flex-direction: column;
  background-color: #392f2e;
  padding: 32px 40px;
  gap: 24px;
  border-radius: 8px 8px 0px 0px;
  height: 50vh;
}

.upperSection {
  height: fit-content;
  align-items: center;
  justify-content: space-between;
}

.logoImg {
  width: 10vw;
  object-fit: contain;
}

.consultButton {
  padding: 10px 24px;
  gap: 10px;
  border-radius: 22px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

.grid {
  height: 100%;
  grid-template-columns: 3fr 3fr 2fr 1fr;
}

.gridItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Push copyright to the bottom */
  height: 100%;
}

.gridTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #fff;
  padding-bottom: 32px;
}

.gridAddress {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #fff;
  opacity: 60%;
}

.gridText {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #fff;
  opacity: 60%;
  padding-bottom: 8px;
}

.gridSocial {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.lowerSection {
  align-items: center;
}

.copyright {
  color: #fff;
  opacity: 60%;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
}

.arrowUpButton {
  align-self: flex-end;
  width: 50px;
  height: 50px;
  top: 50px;
  left: 0px;
  gap: 0px;
  border-radius: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowImg {
  width: 40%;
  width: 40%;
}

.copyrightMobile {
  display: none !important;
}

@media screen and (min-width: 1920px) {
  .consultButton {
    font-size: 1.25rem;
    padding: 10px 24px;
    border-radius: 22px;
  }

  .gridTitle {
    font-size: 1.25rem;
    line-height: normal;
  }

  .gridAddress {
    font-size: 1.2rem;
    line-height: normal;
  }

  .gridText {
    font-size: 1.25rem;
    line-height: normal;
  }

  .gridSocial {
    width: 100%;
  }

  .socialIcon {
    width: 20vw;
  }

  .copyright {
    font-size: 1rem;
  }

  .arrowUpButton {
    width: 3.5rem;
    height: 3.5rem;
  }

  .copyright {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .consultButton {
    padding: 10px 18px;
    font-size: 0.8rem;
  }
  .gridTitle {
    font-size: 0.8rem;
  }

  .gridAddress {
    font-size: 0.8rem;
  }

  .gridText {
    font-size: 0.8rem;
  }

  .copyright {
    padding-top: 32px;
    font-size: 0.5rem;
  }

  .gridSocial {
    flex-direction: column;
    align-items: flex-end;
  }

  .socialIcon {
    width: 3vw;
  }

  .arrowUpButton {
    width: 2rem;
    height: 2rem;
  }
  .arrowImg {
    width: 35%;
    width: 35%;
  }
}

@media screen and (max-width: 790px) {
  .logoImg {
    width: 6rem;
  }
  .gridAddress {
    width: 70%;
  }
}

@media screen and (max-width: 585px) {
  .container {
    height: auto;
  }

  .upperSection {
    flex-direction: column;
    height: auto;
    gap: 64px;
  }

  .logoImg {
    width: 10rem;
  }

  .consultButton {
    padding: 1rem 2.5rem;
    font-size: 1.25rem;
    border-radius: 5rem;
  }

  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }

  .gridItem {
    align-items: center;
  }

  .gridTitle {
    font-size: 1.25rem;
    text-align: center;
  }

  .gridAddress {
    width: 100%;
    text-align: center;
    font-size: 1rem;
  }

  .gridText {
    font-size: 1rem;
    padding-bottom: 8px;
    text-align: center;
  }

  .copyright {
    display: none !important;
  }

  .gridSocial {
    flex-direction: row;
    justify-content: flex-start;
  }

  .socialIcon {
    width: 10vw;
  }

  .arrowUpButton {
    width: 2.5rem;
    height: 2.5rem;
  }
  .arrowImg {
    width: 35%;
    width: 35%;
  }

  .copyrightMobile {
    display: block !important;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  .copyrightMobileText {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: center;
    color: #fff;
    opacity: 60%;
  }
}

@media screen and (max-width: 475px) {
  .consultButton {
    padding: 1rem 2.5rem;
    font-size: 1rem;
    border-radius: 5rem;
  }

  .copyrightMobileText {
    font-size: 1rem;
  }
}
