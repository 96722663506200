/* Compare Section  */
.compareSection {
  flex-direction: column;
  height: auto;
  background-color: #ea2e01;
  align-items: center;
  padding: 64px 160px;
}

.compareTitleText {
  font-size: 71px;
  font-weight: 500;
  letter-spacing: -0.022em;
  text-align: left;
  color: #fff;
}

.compareSubTitleText {
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -0.022em;
  text-align: left;
}

.compareImg {
  width: 100%;
  z-index: 1;
}

.comparisonBoxContainer {
  width: 100%;
  margin-top: 1vh;
}

.comparisonBox {
  background-color: #fff;
  width: 100%;
  padding: 48px 32px;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
}

.comparisonBox1 {
  margin-top: -8vh;
  padding-top: 32px;
}

.comparisonHeading {
  font-size: 48px;
  font-weight: 600;
  line-height: 84px;
  letter-spacing: -0.022em;
  text-align: center;
  width: 30%;
  white-space: nowrap;
}

.comparisonText {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
  width: 28%;
}

.slideText {
  display: none;
}

@media screen and (max-width: 1024px) {
  /* Compare Section  */
  .compareSection {
    padding: 64px 96px;
  }

  .compareTitleText {
    font-size: 3rem;
  }

  .compareSubTitleText {
    font-size: 2rem;
  }

  .comparisonText {
    font-size: 1.2rem;
  }

  .comparisonHeading {
    font-size: 3rem;
  }
}

@media screen and (max-width: 820px) {
  /* Compare Section  */
  .compareSection {
    padding: 32px 36px;
  }

  .comparisonText {
    font-size: 0.8rem;
  }

  .comparisonHeading {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 720px) {
  .compareSection {
    padding: 32px 36px;
  }

  .comparisonText {
    font-size: 0.7rem;
  }

  .comparisonHeading {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 580px) {
  /* Comparison Section  */
  .compareSection {
    width: 100%;
    overflow: visible;
  }

  .compareTitleText {
    font-size: 2rem;
  }

  .compareSubTitleText {
    font-size: 1.5rem;
  }

  .comparisonBoxContainer {
    width: 100%;
    overflow-x: scroll;
    align-self: flex-start;
    margin-top: -2.5vh;
  }

  .comparisonBox {
    width: 125%;
  }
  .compareImg {
    height: 3.5rem;
  }

  .comparisonBox1 {
    margin-top: unset;
    padding-top: 32px;
  }

  .comparisonText {
    width: 25%;
    font-size: 0.75rem;
    line-height: normal;
  }

  .comparisonHeading {
    font-size: 1.5rem;
  }

  .slideText {
    display: block;
    align-self: flex-start;
    color: #fff;
    opacity: 80%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.022em;
    text-align: center;
    text-decoration-skip-ink: none;
  }
}

@media screen and (max-width: 425px) {
  .comparisonBox {
    width: 150%;
  }

  .comparisonText {
    width: 25%;
  }
}

@media screen and (max-width: 350px) {
  .comparisonBox {
    width: 180%;
  }
  .comparisonText {
    width: 25%;
  }
}
