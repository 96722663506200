.headerContainer {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 96px;
}

.headerText {
  text-align: center;
  color: #e93d27;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.headerSubText {
  text-align: center;
  color: darkgray;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contactCards {
  justify-content: space-evenly;
  margin-top: -50px;
  margin-bottom: 64px;
}

.card {
  justify-content: center;
  padding: 32px 16px;
  max-width: 20%;
  border-width: 1px;
}

.cardBody {
  justify-content: center;
  gap: 12px;
}

.cardTitle {
  text-align: center;
  color: black;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 8px;
}

.cardSubText {
  text-align: center;
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-bottom: 16px;
}

.contactText {
  color: black;
  padding-top: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  max-width: 100%;
}

/* FAQ Section  */
.faqSection {
  justify-content: space-between;
  align-items: center;
  padding: 48px 0px;
  flex-direction: column;
}

.questionText {
  text-align: center;
  color: black;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 8px;
}

.questionSubText {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 8px;
}

@media screen and (max-width: 800px) {
  .contactCards {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }

  .card {
    justify-content: center;
    padding: 32px 16px;
    min-width: 70% !important;
  }
  .faqSection {
    padding: 48px 48px;
  }
}

@media screen and (max-width: 480px) {
  .headerContainer {
    justify-content: center;
    padding: 100px 48px;
  }
  .headerText {
    font-size: 3.5rem;
  }

  .headerSubText {
    font-size: 1.5rem;
  }

  .cardTitle {
    font-size: 1.5rem;
  }
  .cardSubText {
    font-size: 1rem;
  }

  .contactText {
    font-size: 1rem;
  }

  .questionText {
    font-size: 1.5rem;
  }

  .questionSubText {
    font-size: 1.1rem;
  }
}
