.container {
  flex-direction: column;
}

.headerSection {
  position: relative;
  min-height: 55vh;
  height: fit-content;
  background-image: url("/public/homeHeaderBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.headerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headerTextContainer {
  padding: 32px 160px;
  flex-direction: column;
  height: 100%;
  position: absolute;
  justify-content: center;
  color: white;
  z-index: 2;
  align-items: flex-start;
}

.headerTitleText {
  text-align: left;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -0.022em;
  line-height: normal;
  color: #fbfbfb;
}

.headerSubTitle {
  font-size: 68px;
  font-weight: 500;
  letter-spacing: -0.022em;
  text-align: left;
  line-height: normal;
  color: #fbfbfb;
}

.headerDescContainer {
  justify-content: space-between;
  align-items: center;
}

.headerDescText {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.022em;
  text-align: left;
  color: #fbfbfb;
  width: 60%;
  margin-top: 16px;
}

.consultButton {
  height: auto !important;
  padding: 12px 30px !important;
  border-radius: 0px !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  color: #8d8a8a !important;
}

.headerImg2 {
  height: 40vh;
  object-fit: cover;
}

/* Carousel Section  */
.carouselSection {
  background-color: #fff;
  height: 300vh;
  background-image: url("/public/USP_background.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.scrollSection {
  height: 100vh;
  position: sticky;
  top: 0;
  padding: 8px 0 32px 160px;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.scrollLine {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8vw;
}

.contentContainer {
  flex-direction: column;
  width: fit-content;
  padding-top: 32px;
  padding-left: 10vw;
}

.kuriaBar {
  width: 80%;
  height: 10rem;
  margin: 16px 0 0 0;
}

.carouselTitleText {
  position: relative;
  font-size: 58px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.022em;
  text-align: left;
}

.carouselSubTitleText {
  font-size: 36px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -0.022em;
  text-align: center;
}

.scrollBoxContainer {
  gap: 8vw;
}

.scrollBox {
  flex-direction: column;
  width: 30vw;
}

.scrollIcon {
  width: 3rem;
}

.scrollTitle {
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02em;
  text-align: left;
  padding-bottom: 4px;
}

.scrollText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

/* Application Section  */

.applicationSection {
  flex-direction: column;
  max-height: 120vh;
  background-color: #fff;
  padding-top: 32px;
  align-items: center;
}

.applicationHeader {
  font-size: 71px;
  font-weight: 500;
  letter-spacing: -0.022em;
  text-align: left;
  padding-bottom: 32px;
}

.applicationImgContainer {
  height: 100%;
  width: 100%;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
}

.applicationImg {
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.applicationTextContainer {
  flex-direction: column;
  height: 40%;
  background-color: #efefef;
  padding: 42px;
  gap: 16px;
}

.applicationText {
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  word-wrap: break-word;
}

.applicationSubText {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
}

.callOutBanner {
  flex-direction: column;
  height: 55vh;
  background-image: url(/public/callOutBanner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
}

.callOutText {
  font-size: 71px;
  font-weight: 500;
  line-height: 106.5px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #fff;
}

.callOutButton {
  height: auto;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.022em;
  text-align: center;
  padding: 12px 30px 12px 30px;
  background-color: #f5f6f7;
  border-color: #fff;
  color: #392f2e;
}

.sliderContainer {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  overflow: hidden;
  display: none;
}

.slide {
  background-color: #e93d27;
  display: none;
}

@media screen and (min-width: 1920px) {
  .headerTextContainer {
    padding: 48px 180px;
  }

  .headerTitleText {
    font-size: 4rem;
  }

  .headerSubTitle {
    font-size: 6rem;
  }
  .headerDescText {
    font-size: 1.7rem;
  }

  .consultButton {
    font-size: 1.8rem !important;
    padding: 14px 36px !important;
  }

  /* Carousel Section  */
  .scrollSection {
    padding: 48px 0 48px 160px;
  }

  .kuriaBar {
    height: 15rem;
    margin: 24px 0;
  }

  .carouselTitleText {
    font-size: 3.5rem;
    line-height: normal;
  }

  .carouselSubTitleText {
    font-size: 2.5rem;
    line-height: normal;
  }

  .scrollIcon {
    width: 3.5rem;
  }

  .scrollTitle {
    font-size: 2rem;
    line-height: normal;
  }

  .scrollText {
    font-size: 1.2rem;
    line-height: 1.5rem;
    width: 60%;
  }

  .comparisonText {
    font-size: 1.5rem;
  }

  .comparisonHeading {
    font-size: 4rem;
  }

  .applicationHeader {
    font-size: 5rem;
    padding-bottom: 32px;
  }

  .applicationText {
    font-size: 2.5rem;
    line-height: normal;
  }

  .applicationSubText {
    font-size: 1.5rem;
    line-height: normal;
  }

  .callOutText {
    font-size: 6rem;
    line-height: normal;
    padding-bottom: 32px;
  }

  .callOutButton {
    font-size: 2rem;
    line-height: normal;
    padding: 24px 36px;
  }
}

@media screen and (max-width: 1024px) {
  .headerTextContainer {
    padding: 0 96px;
  }
  .headerTitleText {
    font-size: 1.8rem;
  }

  .headerSubTitle {
    font-size: 3rem;
  }

  .headerDescText {
    font-size: 1rem;
  }

  .consultButton {
    width: 151px;
    font-size: 1rem;
  }

  /* Carousel Section  */

  .scrollSection {
    padding: 32px 0 32px 120px;
  }

  .contentContainer {
    padding-left: 2vw;
  }

  .kuriaBar {
    height: 8rem;
  }

  .carouselTitleText {
    font-size: 2.5rem;
    line-height: normal;
  }

  .carouselSubTitleText {
    font-size: 1.5rem;
    line-height: normal;
  }

  .scrollBox {
    width: 35vw;
  }

  .scrollIcon {
    width: 2.5rem;
  }

  .scrollTitle {
    font-size: 1.5rem;
    line-height: normal;
    padding-bottom: unset;
  }

  .scrollText {
    padding-top: 8px;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .applicationSection {
    max-height: auto;
  }

  .applicationHeader {
    font-size: 4rem;
    padding-bottom: 16px;
  }

  .applicationTextContainer {
    padding: 24px;
    gap: 12px;
  }

  .applicationImg {
    height: 60%;
  }

  .applicationText {
    font-size: 1.25rem;
  }

  .applicationSubText {
    font-size: 0.8rem;
  }

  .callOutText {
    font-size: 4rem;
  }

  .callOutButton {
    font-size: 1.25rem;
    padding: 10px 28px;
  }
}

@media screen and (max-width: 820px) {
  .headerTextContainer {
    padding: 0 64px;
  }
  .headerTitleText {
    font-size: 1.5rem;
  }

  .headerSubTitle {
    font-size: 2.5rem;
  }

  .headerDescText {
    font-size: 1rem;
  }

  .consultButton {
    font-size: 0.8rem;
  }

  /* Carousel Section  */

  .scrollBox {
    width: 30vw;
  }

  .scrollTitle {
    font-size: 1.4rem;
    line-height: normal;
    padding-top: 4px;
  }

  .scrollText {
    padding-top: 16px;
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .applicationHeader {
    font-size: 3rem;
  }

  .applicationTextContainer {
    gap: 8px;
    height: 200px;
  }

  .applicationText {
    font-size: 2rem;
    line-height: normal;
  }

  .applicationSubText {
    font-size: 1.2rem;
    line-height: normal;
  }

  .callOutText {
    font-size: 3.5rem;
  }

  .callOutButton {
    font-size: 1rem;
    padding: 10px 16px;
  }

  .applicationImgContainer {
    display: none !important;
  }

  .sliderContainer {
    display: block;
  }

  .slide {
    display: block;
  }
}

@media screen and (max-width: 720px) {
  .headerTitleText {
    font-size: 1.5rem;
  }

  .headerSubTitle {
    font-size: 2.25rem;
  }

  .headerDescContainer {
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
    gap: 5vh;
  }

  .headerDescText {
    width: 100%;
    font-size: 0.8rem;
  }

  .consultButton {
    align-self: center;
    padding: 1rem 4rem;
    font-size: 0.8rem;
    line-height: normal;
  }

  /* Carousel Section  */
  .scrollSection {
    padding: 16px 0 32px 48px;
  }

  .carouselTitleText {
    font-size: 1.8rem;
    line-height: normal;
  }

  .scrollBoxContainer {
    gap: 12vw;
  }

  .scrollBox {
    width: 35vw;
  }

  .scrollTitle {
    font-size: 1.2rem;
    line-height: normal;
    padding-top: 4px;
  }

  .scrollText {
    padding-top: 8px;
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

@media screen and (max-width: 525px) {
  .headerSection {
    min-height: 48vh;
  }

  .headerTextContainer {
    padding: 0 32px;
  }

  .headerTitleText {
    font-size: 24px;
  }

  .headerSubTitle {
    font-size: 32px;
  }

  .headerDescText {
    margin-top: 10px;
    font-size: 16px;
  }

  .headerDescContainer {
    gap: 8vh;
  }

  .consultButton {
    padding: 6px 14px;
    font-size: 16px !important;
  }

  /* Carousel Section  */
  .scrollBox {
    width: 35vw;
  }

  .scrollIcon {
    width: 2rem;
  }

  .applicationSection {
    height: auto;
    padding-top: 32px;
  }

  .applicationImgContainer {
    display: none !important;
  }

  .applicationImg {
    width: 100%;
    /* TODO: find better way to make height uniform in mobile */
    height: 490px;
  }

  .applicationHeader {
    font-size: 2rem;
    padding-bottom: 32px;
  }

  .applicationTextContainer {
    gap: 8px;
    height: 200px;
  }

  .applicationText {
    font-size: 28px;
  }

  .applicationSubText {
    font-size: 20px;
  }

  .callOutText {
    font-size: 2rem;
    white-space: nowrap;
  }

  .callOutButton {
    font-size: 0.7rem;
    padding: 5px 12px;
  }
}

@media screen and (max-width: 450px) {
  /* Carousel Section  */
  .carouselTitleText {
    font-size: 1.5rem;
  }

  .carouselSubTitleText {
    font-size: 1.25rem;
  }

  .scrollTitle {
    font-size: 1rem;
  }

  .scrollText {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 375px) {
  .headerTitleText {
    font-size: 1.25rem;
  }

  .headerSubTitle {
    font-size: 1.8rem;
  }

  .headerDescContainer {
    gap: 3vh;
  }

  .headerDescText {
    font-size: 0.7rem;
  }

  .consultButton {
    padding: 1rem 4rem;
    font-size: 0.5rem;
    line-height: normal;
  }

  .carouselTitleText {
    font-size: 1.25rem;
  }

  .carouselSubTitleText {
    font-size: 1rem;
  }

  .scrollTitle {
    font-size: 1rem;
  }

  .scrollText {
    font-size: 0.7rem;
  }
}
