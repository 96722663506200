.container {
  flex-direction: column;
}

.headerSection {
  min-height: 120vh;
  height: fit-content;
  background-image: url(/public/aboutHeader.png);
  background-size: 100% 65%; /* Full width, 70% height */
  background-repeat: no-repeat;
  background-position: top;
  padding: 120px 160px 48px 160px;
  gap: 54px;
}

.leftTextSection {
  flex-direction: column;
  width: 50vw;
  align-items: flex-start;
}

.headerText {
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #fff;
}

.headerText2 {
  font-size: 72px;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #fff;
}

.headerImgRight {
  width: auto;
  height: 60vh;
  object-fit: cover;
}

.headerImgRightMobile {
  display: none !important;
}

.headerImgLeft {
  padding-top: 32px;
  width: 100%;
}

/* Values Section  */
.valuesSection {
  flex-direction: column;
  padding: 48px 160px 128px 160px;
}

.valuesTitle {
  font-size: 71px;
  font-weight: 500;
  line-height: 71px;
  letter-spacing: -0.022em;
  text-align: left;
  padding-bottom: 48px;
}

.visionMission {
  width: 100%;
  justify-content: space-between;
}

.visionCard {
  flex-direction: column;
  justify-content: center;
  width: 48%;
  border: 1px solid #ea2e01;
  height: 35vh;
  padding: 0 48px;
}

.visionTitle {
  font-size: 56px;
  font-weight: 300;
  line-height: 84px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #392f2e;
}

.visionText {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #222;
  width: 80%;
}

/* Journey Section   */
.journeySection {
  flex-direction: column;
  padding: 120px 160px;
  background-color: #ea2e01;
}

.journeyTitle {
  font-size: 71px;
  font-weight: 500;
  line-height: 71px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #fff;
  padding-bottom: 32px;
}

.journeyContainer {
  flex-direction: column;
  gap: 3vh;
}

.journeyCard {
  height: 25vh;
  align-items: flex-end;
  width: 100%;
  grid-template-areas: "year img desc";
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
}

.yearText {
  grid-area: year;
  font-size: 120px;
  line-height: 1;
  font-weight: 300;
  letter-spacing: -0.022em;
  color: #fff;
  opacity: 80%;
  margin-top: auto;
}

.journeyImg {
  grid-area: img;
  width: 80%;
}

.journeyDesc {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #fff;
  width: 80%;
}

.callOutBanner {
  flex-direction: column;
  height: 55vh;
  background-image: url(/public/callOutBanner.png);
  align-items: center;
  justify-content: center;
}

.callOutText {
  font-size: 71px;
  font-weight: 500;
  line-height: 106.5px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #fff;
}

.callOutButton {
  height: auto;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.022em;
  text-align: center;
  padding: 12px 30px 12px 30px;
  background-color: #f5f6f7;
  border-color: #fff;
  color: #392f2e;
}

@media screen and (min-width: 2200px) {
  .journeyTitle {
    font-size: 82px;
    padding-bottom: 72px;
  }

  .yearText {
    font-size: 160px;
  }

  .journeyDesc {
    font-size: 28px;
    line-height: normal;
  }
}

@media screen and (max-width: 1024px) {
  .headerSection {
    min-height: 80vh;
    height: 90vh;
    padding: 72px 72px 48px 72px;
  }

  .headerText {
    font-size: 2rem;
    line-height: 2.2rem;
  }

  .headerText2 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .headerImgRight {
    height: 45vh;
  }

  .valuesSection {
    padding: 64px 72px 96px 72px;
  }

  .valuesTitle {
    font-size: 3rem;
    line-height: 3rem;
  }

  .visionTitle {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .visionText {
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
  }

  /* Journey Section  */
  .journeySection {
    padding: 96px 72px;
  }

  .journeyTitle {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  .yearText {
    font-size: 5rem;
    margin-top: auto;
  }

  .journeyImg {
    width: 100%;
  }

  .journeyDesc {
    font-size: 0.9rem;
    line-height: normal;
    width: 90%;
  }

  .callOutText {
    font-size: 3.5rem;
    line-height: 3.5rem;
    text-align: center;
    width: 80%;
  }

  .callOutButton {
    margin-top: 32px;
    font-size: 1.2rem;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 845px) {
  .headerSection {
    min-height: 60vh;
    height: 80vh;
    padding: 72px 72px 0px 72px;
    gap: 36px;
  }

  .headerText {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  .headerText2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .valuesSection {
    padding: 32px 72px 96px 72px;
  }

  .valuesTitle {
    font-size: 3rem;
    line-height: 3rem;
  }

  .visionCard {
    padding: 24px 24px;
  }

  .visionTitle {
    font-size: 2.25rem;
    line-height: 2.5rem;
    padding-bottom: 12px;
  }

  .visionText {
    font-size: 1rem;
    line-height: normal;
    width: 100%;
  }

  /* Journey Section  */
  .journeySection {
    padding: 72px 48px;
  }

  .journeyTitle {
    font-size: 3rem;
    line-height: 3rem;
  }

  .yearText {
    font-size: 4rem;
  }

  .journeyImg {
    width: 30vw;
  }

  .journeyDesc {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  /* Call Out Section  */
  .callOutText {
    font-size: 2.8rem;
    line-height: 2.8rem;
    text-align: center;
    width: 80%;
  }

  .callOutButton {
    margin-top: 32px;
    font-size: 1rem;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 720px) {
  .headerSection {
    height: 75vh;
    padding: 54px 48px 0 48px;
  }

  .headerImgRight {
    height: 38vh;
    width: 40%;
  }

  .headerText {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .headerText2 {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  /* Values Section  */
  .valuesSection {
    padding: 32px 48px 96px 48px;
  }

  .visionCard {
    height: 30vh;
  }

  .visionTitle {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .visionText {
    font-size: 0.8rem;
  }

  /* Journey Section  */
  .journeySection {
    padding: 48px 48px;
  }

  .journeyTitle {
    font-size: 2.5rem;
    line-height: 2.5rem;
    padding-bottom: 0;
  }

  .yearText {
    font-size: 4rem;
  }

  .journeyImg {
    width: 30vw;
  }

  .journeyDesc {
    font-size: 0.7rem;
    line-height: 1rem;
  }

  .callOutText {
    font-size: 2.25rem;
    line-height: 2.25rem;
  }

  .callOutButton {
    margin-top: 16px;
    font-size: 0.8rem;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 590px) {
  .headerSection {
    height: 100vh;
    background-size: 100% 75%;
    padding: 54px 48px 48px 48px;
  }

  .leftTextSection {
    width: 100%;
  }
  .headerText {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .headerText2 {
    font-size: 2rem;
    line-height: 2rem;
  }

  .headerImgRightMobile {
    display: block !important;
    padding-top: 32px;
    width: 60%;
    height: 40vh;
    object-fit: cover;
    align-self: flex-end;
  }

  .headerImgRight {
    display: none !important;
  }

  .headerImgLeft {
    width: 80%;
  }

  .valuesSection {
    padding: 72px 48px 96px 48px;
  }

  .valuesTitle {
    font-size: 2rem;
    line-height: 2rem;
    padding-bottom: 32px;
  }

  .visionMission {
    flex-direction: column;
    gap: 24px;
  }

  .visionCard {
    width: 100%;
    height: 20vh;
  }

  .visionTitle {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  .visionText {
    font-size: 0.8rem;
  }

  /* Journey Section  */
  .journeyTitle {
    font-size: 2.5rem;
    padding-bottom: 32px;
  }
  .journeyCard {
    grid-template-areas:
      "year img"
      "year desc" !important;
    grid-template-columns: 1fr 1fr;
    height: fit-content;
  }

  .yearText {
    font-size: 4rem;
    width: 100%;
  }

  .journeyImg {
    align-self: flex-end;
    width: 100%;
  }

  .journeyDesc {
    font-size: 1rem;
    line-height: 1.2rem;
    width: 100%;
  }

  /* Call Out Section  */
  .callOutText {
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .callOutButton {
    margin-top: 28px;
    font-size: 18px;
    padding: 10px 24px;
  }
}

@media screen and (max-width: 425px) {
  .journeyTitle {
    font-size: 2rem;
  }

  .yearText {
    font-size: 4rem;
  }

  .journeyDesc {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

@media screen and (max-width: 375px) {
  .headerSection {
    padding: 54px 32px 0px 32px;
  }

  .headerText {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .headerText2 {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }

  .headerImgRightMobile {
    height: 35vh;
  }

  .valuesSection {
    padding: 0px 32px 96px 32px;
  }

  /* Journey Section  */
  .journeyTitle {
    font-size: 2rem;
  }

  .yearText {
    font-size: 3rem;
  }

  .journeyDesc {
    font-size: 0.6rem;
    line-height: 0.8rem;
  }
}
