.container {
  flex-direction: column;
  overflow: hidden;
}

.headerContainer {
  flex-direction: column;
  height: 90vh;
  background-color: #efefef;
}

.backgroundOverlay {
  position: absolute;
  width: 70%;
  height: 90vh;
  background-color: #fff;
  clip-path: polygon(0 0, 80% 0%, 100% 100%, 0 100%);
}

.kuriaBarImg {
  position: absolute;
  width: 85%;
  top: 25vh;
  left: 5rem;
}

.kuriaBarGrey {
  position: absolute;
  width: 90%;
  top: 38vh;
  align-self: flex-end;
}

.headerTextContainer {
  flex-direction: column;
  padding: 100px 160px;
}

.headerTitleText {
  position: relative;
  font-size: 200px;
  font-weight: 700;
  line-height: 220px;
  letter-spacing: -0.022em;
  color: #e93d27;
}

/* Info Section  */
.infoSection {
  min-height: 360px;
  height: fit-content;
  width: 100%;
  padding: 32px 160px;
  background-image: url(/public/prodInfoBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  flex-direction: column;
}

.infoText {
  font-size: 38px;
  font-weight: 300;
  line-height: 54.4px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #fff;
  width: 100%;
}

.infoText2 {
  font-size: 38px;
  font-weight: 500;
  line-height: 54.4px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #fff;
  width: 85%;
}

/* Sizes Section  */
.sizesSection {
  flex-direction: column;
  align-items: center;
  padding: 48px 160px 32px 160px;
  height: fit-content;
  min-height: 100vh;
}

.sizesTitle {
  font-size: 64px;
  font-weight: 500;
  line-height: 106.5px;
  letter-spacing: -0.022em;
  padding-bottom: 16px;
}

.sizesSubTitle {
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
  letter-spacing: -0.022em;
  text-align: center;
}

.sizesSubTitleMobile {
  display: none !important;
}

.sizesImg {
  width: 80%;
  align-self: center;
  margin-top: 1vh;
}

.sizesImgMobile {
  display: none !important;
}

.sizesTextContainer {
  padding-top: 8px;
  flex-direction: column;
  gap: 18px;
}

.sizesText {
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.022em;
  text-align: left;
}

/* Compare Section  */
.compareSection {
  flex-direction: column;
  height: fit-content;
  background-color: #ea2e01;
  align-items: center;
  padding: 64px 100px;
}

.compareTitleText {
  font-size: 71px;
  font-weight: 500;
  letter-spacing: -0.022em;
  text-align: left;
  color: #fff;
}

.compareSubTitleText {
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -0.022em;
  text-align: left;
}

.compareImg {
  width: 100%;
  z-index: 1;
}

.comparisonBoxContainer {
  width: 100%;
}

.comparisonBox {
  background-color: #fff;
  width: 100%;
  padding: 16px 32px;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
}

.comparisonText {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
  width: 28%;
}

.comparisonHeading {
  font-size: 48px;
  font-weight: 500;
  line-height: 84px;
  letter-spacing: -0.022em;
  text-align: center;
  width: 30%;
  white-space: nowrap;
}

.comparisonBox1 {
  margin-top: -8vh;
  align-items: center;
  padding-top: 32px;
}

/* Scientifically Proven Section  */
.scientificSection {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 64px 160px;
  background-color: #efefef;
}

.scientificTitle {
  font-size: 71px;
  font-weight: 500;
  line-height: 106.5px;
  letter-spacing: -0.022em;
  text-align: left;
  padding-bottom: 32px;
}

.scientificCardUpper {
  width: 100%;
  gap: 2vw;
}

.scientificCard {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 40vh;
  background-color: #fff;
  padding: 32px 32px;
}

.scientificCard3 {
  margin-top: 32px;
  width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 32px 32px;
}

.scientificCardTitle {
  font-size: 48px;
  font-weight: 300;
  letter-spacing: -0.022em;
  text-align: center;
}

.scientificCardText {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.02em;
  text-align: center;
  padding-bottom: 8px;
}

.scientificCardImg {
  width: 100%;
}

/* Application Section  */
.applicationSection {
  flex-direction: column;
  height: fit-content;
  background-color: #fff;
  padding-top: 32px;
  align-items: center;
}

.applicationHeader {
  font-size: 71px;
  font-weight: 500;
  letter-spacing: -0.022em;
  text-align: left;
  padding-bottom: 16px;
}

.applicationImgContainer {
  height: 100%;
  width: 100%;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
}

.applicationImg {
  width: 100%;
  object-fit: cover;
}

.applicationTextContainer {
  flex-direction: column;
  height: 40%;
  background-color: #efefef;
  padding: 36px;
  gap: 16px;
}

.applicationText {
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  white-space: nowrap;
}

.applicationSubText {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
}

.sliderContainer {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  overflow: hidden;
  display: none;
}

/* Projects Section  */
.projectsSection {
  flex-direction: column;
  align-items: center;
  padding: 64px 160px;
  background-color: #392f2e;
  background-image: url(/public/Supergraphic\ abu-05.png);
}

.projectsTitle {
  font-size: 71px;
  font-weight: 500;
  line-height: 73.13px;
  letter-spacing: -0.022em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  padding-bottom: 32px;
}

.carouselContainer {
  width: 100%;
  height: auto;
  max-width: 100vw;
  overflow: hidden;
}

.slide {
  height: 80vh;
  background-color: #e93d27;
}

.carouselImg {
  height: 100%;
  width: 100%;
}

.arrow {
  height: auto;
}

.callOutBanner {
  flex-direction: column;
  height: 55vh;
  background-image: url(/public/callOutBanner.png);
  align-items: center;
  justify-content: center;
}

.callOutText {
  font-size: 71px;
  font-weight: 500;
  line-height: 106.5px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #fff;
}

.callOutButton {
  height: auto;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.022em;
  text-align: center;
  padding: 12px 30px 12px 30px;
  background-color: #f5f6f7;
  border-color: #fff;
  color: #392f2e;
}

@media screen and (min-width: 1900px) {
  .headerTextContainer {
    padding: 160px 180px;
  }

  .headerTitleText {
    font-size: 20rem;
    line-height: 20.5rem;
  }

  .infoText {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .infoText2 {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .sizesSection {
    padding: 64px 180px 0px 180px;
  }

  .sizesTitle {
    font-size: 6rem;
    line-height: 6.5rem;
  }

  .sizesSubTitle {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  /* Comparison Section  */
  .compareSection {
    padding: 64px 180px 64px 180px;
  }

  .compareTitleText {
    font-size: 5.5rem;
  }

  .compareSubTitleText {
    font-size: 4rem;
  }

  .compareImg {
    transform: translateY(28%);
  }

  .comparisonBox {
    padding: 48px 64px;
  }

  .comparisonBox1 {
    margin-top: unset;
    padding-top: 72px 48px 64px 48px;
  }

  .comparisonHeading {
    font-size: 4rem;
    line-height: 4.2rem;
  }

  .comparisonText {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  /* Scientific Section  */
  .scientificSection {
    padding: 64px 180px;
  }

  .scientificTitle {
    font-size: 6rem;
    line-height: 6.5rem;
  }

  .scientificCard3 {
    margin-top: 64px;
  }

  .scientificCardTitle {
    font-size: 4rem;
  }

  .scientificCardText {
    font-size: 2.5rem;
  }

  /* Application Section  */
  .applicationSection {
    padding-top: 64px;
  }

  .applicationHeader {
    font-size: 5.5rem;
    padding-bottom: 32px;
  }

  .applicationText {
    font-size: 2.25rem;
    line-height: normal;
  }

  .applicationSubText {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  /* Projects Section  */
  .projectsSection {
    padding: 72px 180px;
  }

  .projectsTitle {
    font-size: 5.5rem;
    line-height: 6rem;
  }
}

@media screen and (min-width: 1200px) {
  .compareImg {
    transform: translateY(28%);
  }

  .comparisonBox1 {
    margin-top: unset;
    padding-top: 48px 48px 48px 48px;
  }
}

@media screen and (max-width: 1200px) {
  .headerTextContainer {
    padding: 96px 96px 48px 84px;
  }

  .infoSection {
    padding: 32px 84px;
  }

  .sizesSection {
    padding: 32px 0px 32px 0;
  }

  .sizesTitle {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  .sizesSubTitle {
    font-size: 2rem;
    line-height: 2.2rem;
  }

  .comparisonBox {
    padding-top: unset;
    padding: 36px;
  }

  .comparisonBox1 {
    padding: 48px 32px 24px 32px;
  }

  .comparisonText {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .scientificSection {
    padding: 32px 84px;
  }

  .scientificTitle {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  .scientificCardTitle {
    font-size: 2.25rem;
  }

  .scientificCardText {
    font-size: 1.5rem;
  }

  .applicationSection {
    padding-top: 32px;
  }

  .applicationImg {
    height: 60%;
  }

  .applicationHeader {
    font-size: 4rem;
    padding-bottom: 16px;
  }

  .applicationText {
    font-size: 1.5rem;
  }

  .applicationSubText {
    font-size: 1rem;
  }

  .projectsSection {
    padding: 64px 84px;
  }

  .projectsTitle {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

@media screen and (max-width: 1024px) {
  .headerContainer {
    height: 75vh;
  }

  .backgroundOverlay {
    height: 75vh;
  }

  .headerTextContainer {
    padding: 96px 96px 48px 96px;
  }

  .headerTitleText {
    font-size: 10rem;
    line-height: 10rem;
  }

  .infoSection {
    padding: 32px 96px;
  }

  .infoText {
    font-size: 2.2rem;
    line-height: 2.5rem;
    width: 100%;
  }

  .infoText2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sizesSection {
    padding: 48px 0 0 0;
    min-height: 80vh;
  }

  .sizesTitle {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .sizesSubTitle {
    font-size: 2rem;
    line-height: 2.2rem;
  }

  .sizesTextContainer {
    padding-top: unset;
    gap: 20px;
  }

  .sizesText {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  .compareSection {
    padding: 64px 96px;
  }

  .compareTitleText {
    font-size: 3.5rem;
  }

  .compareSubTitleText {
    font-size: 2.5rem;
  }

  .compareTitleText {
    font-size: 3rem;
  }

  .compareSubTitleText {
    font-size: 2rem;
  }

  .comparisonText {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .comparisonHeading {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }

  .scientificSection {
    padding: 64px 72px;
  }

  .scientificTitle {
    font-size: 3.25rem;
    line-height: 3.75rem;
  }

  .scientificCard {
    height: 35vh;
  }

  .scientificCardTitle {
    font-size: 2.25rem;
  }

  .scientificCardText {
    font-size: 1.25rem;
  }

  .applicationSection {
    padding-top: 32px;
  }

  .applicationImg {
    height: 60%;
  }

  .applicationHeader {
    font-size: 3.5rem;
    padding-bottom: 16px;
  }

  .applicationText {
    font-size: 1.25rem;
    line-height: normal;
  }

  .applicationSubText {
    font-size: 1rem;
    line-height: normal;
  }

  .projectsSection {
    padding: 64px 96px;
    height: 100vh;
  }

  .projectsTitle {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  .callOutText {
    font-size: 4rem;
  }

  .callOutButton {
    font-size: 1.25rem;
    padding: 10px 28px;
  }

  .projectsSection {
    padding: 64px 96px;
  }

  .projectsTitle {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

@media screen and (max-width: 820px) {
  .headerContainer {
    height: 60vh;
  }

  .backgroundOverlay {
    height: 60vh;
  }

  .headerTextContainer {
    padding: 96px 72px 48px 72px;
  }

  .headerTitleText {
    font-size: 7rem;
    line-height: 7rem;
  }

  .infoSection {
    padding: 32px 72px;
  }

  .infoText {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .infoText2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .sizesSection {
    padding: 48px 0 0px 0;
    min-height: 80vh;
  }

  .sizesTitle {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .sizesSubTitle {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sizesTextContainer {
    padding-top: unset;
    gap: 16px;
  }

  .sizesText {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .compareSection {
    padding: 32px 72px;
  }

  .compareTitleText {
    font-size: 2.8rem;
  }

  .compareSubTitleText {
    font-size: 2rem;
  }

  .comparisonText {
    font-size: 0.75rem;
  }

  .comparisonHeading {
    font-size: 2.1rem;
  }

  .scientificSection {
    padding: 64px 36px;
  }

  .scientificTitle {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .scientificCardTitle {
    font-size: 2rem;
  }

  .scientificCardText {
    font-size: 1rem;
  }

  .applicationImgContainer {
    display: none !important;
  }

  .sliderContainer {
    display: block;
  }

  .applicationHeader {
    font-size: 3.5rem;
    padding-bottom: 32px;
  }

  .applicationImg {
    width: 100%;
    height: 60vh;
  }

  .applicationText {
    font-size: 2rem;
  }

  .applicationSubText {
    font-size: 1.25rem;
  }

  .applicationTextContainer {
    height: 25vh;
  }

  .callOutText {
    font-size: 3.5rem;
  }

  .callOutButton {
    font-size: 1rem;
    padding: 10px 16px;
  }

  .projectsSection {
    padding: 64px 72px;
  }

  .projectsTitle {
    font-size: 2.8rem;
    line-height: 3.3rem;
    padding-bottom: 24px;
  }
}
@media screen and (max-width: 720px) {
  .headerContainer {
    height: 55vh;
    max-width: 100vw;
  }

  .backgroundOverlay {
    height: 55vh;
  }

  .headerTitleText {
    font-size: 6rem;
    line-height: 6.5rem;
  }

  .infoSection {
    padding: 32px 54px;
  }

  .infoText {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }

  .infoText2 {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }

  .sizesSection {
    padding: 32px 0 0px 0;
    min-height: 60vh;
  }

  .sizesTitle {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sizesSubTitle {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  /* Comparison Section  */
  .compareSection {
    padding: 32px 54px;
  }

  .comparisonBox1 {
    margin-top: -6vh;
    padding: 16px 32px;
    align-items: center;
  }

  .comparisonBox {
    padding: 32px 16px;
    align-items: center;
  }

  .compareTitleText {
    font-size: 2.5rem;
  }

  .compareSubTitleText {
    font-size: 1.75rem;
  }

  .comparisonHeading {
    font-size: 1.8rem;
    line-height: normal;
  }

  .comparisonText {
    font-size: 0.75rem;
    line-height: normal;
  }

  /* Scientifically Proven Section  */
  .scientificSection {
    padding: 32px 54px;
  }

  .scientificTitle {
    font-size: 2rem;
    line-height: 2.25rem;
    padding-bottom: 16px;
  }

  .scientificCardUpper {
    flex-direction: column;
    align-items: center;
  }

  .scientificCard {
    width: 100%;
    height: 30vh;
  }

  .scientificCard3 {
    width: 100%;
    height: 30vh;
    margin-top: 2vw;
  }

  .scientificCardTitle {
    font-size: 1.25rem;
  }
  .scientificCardText {
    font-size: 0.7rem;
  }

  /* Application Section  */
  .applicationSection {
    padding-top: 28px;
  }

  .applicationHeader {
    font-size: 2.5rem;
  }

  .applicationTextContainer {
    height: 30vh;
  }

  .applicationText {
    font-size: 1.8rem;
  }

  .applicationSubText {
    font-size: 1.25rem;
  }

  .projectsSection {
    padding: 32px 54px;
    height: fit-content;
  }

  .projectsTitle {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .slide {
    height: 50vh;
  }
}

@media screen and (max-width: 620px) {
  .compareSection {
    width: 100%;
    overflow: visible;
  }

  .compareTitleText {
    font-size: 2.5rem;
  }

  .compareSubTitleText {
    font-size: 1.8rem;
  }

  .comparisonBoxContainer {
    width: 100%;
    overflow-x: scroll;
    align-self: flex-start;
    margin-top: -2.5vh;
  }

  .comparisonBox {
    padding: 20px 16px;
    width: 150%;
  }

  .comparisonBox1 {
    margin-top: unset;
    padding-top: unset;
    padding: 20px 16px;
  }

  .comparisonText {
    width: 20%;
    font-size: 0.75rem;
    line-height: normal;
  }

  .comparisonHeading {
    font-size: 2.5rem;
  }

  .projectsTitle {
    font-size: 2.2rem;
    line-height: 2.7rem;
  }

  .slide {
    height: 35vh;
  }
}

@media screen and (max-width: 525px) {
  .container {
    overflow: hidden;
  }

  .headerContainer {
    height: 45vh;
  }

  .backgroundOverlay {
    height: 45vh;
  }

  .headerTextContainer {
    padding: 96px 24px 32px 24px;
  }

  .headerTitleText {
    font-size: 5rem;
    line-height: 5.2rem;
    white-space: nowrap;
  }

  .kuriaBarImg {
    overflow: hidden;
    height: 20vh;
    width: 70%;
    top: 20vh;
  }

  .kuriaBarGrey {
    height: 20vh;
    width: 80%;
    top: 30vh;
  }

  /* Info Section  */
  .infoSection {
    padding: 32px 24px;
  }

  .infoText {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  .infoText2 {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  .sizesSection {
    padding: 38px 24px 38px 0;
    min-height: 35vh;
    align-items: flex-start;
  }

  .sizesTitle {
    padding: 0 0 0 24px;
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .sizesSubTitle {
    display: none !important;
  }

  .sizesSubTitleMobile {
    font-size: 1.3rem;
    line-height: 1.5rem;
    padding: 4px 24px 0 24px;
    display: block !important;
  }

  .sizesImg {
    display: none !important;
  }

  .sizesImgMobile {
    display: block !important;
    width: 100%;
    height: auto;
    padding-top: 16px;
  }

  /* Comparison Section  */
  .compareSection {
    width: 100%;
    overflow: visible;
  }

  .compareTitleText {
    font-size: 2rem;
  }

  .compareSubTitleText {
    font-size: 1.5rem;
  }

  .comparisonBoxContainer {
    width: 100%;
    overflow-x: scroll;
    align-self: flex-start;
    margin-top: -2.5vh;
  }

  .comparisonBox {
    width: 200%;
    padding: 16px 24px;
  }

  .comparisonBox1 {
    margin-top: unset;
    padding-top: unset;
    padding: 16px 24px;
  }

  .comparisonText {
    width: 20%;
    font-size: 1rem;
    line-height: normal;
  }

  .comparisonHeading {
    font-size: 2.5rem;
  }

  /* Scientific Section  */
  .scientificSection {
    padding: 32px 24px;
  }

  .scientificTitle {
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .scientificCardTitle {
    font-size: 1.5rem;
  }

  .scientificCardText {
    font-size: 0.8rem;
  }

  /* Application Section  */

  .applicationSection {
    min-height: none;
  }
  .applicationHeader {
    font-size: 2.25rem;
  }

  .applicationTextContainer {
    height: 25vh;
  }

  .applicationImg {
    height: 60vh;
  }

  .applicationText {
    font-size: 1.5rem;
  }

  .applicationSubText {
    font-size: 1rem;
  }

  /* Projects Section  */
  .projectsSection {
    padding: 32px 36px;
    height: 60vh;
  }

  .projectsTitle {
    font-size: 2.5rem;
  }

  .callOutText {
    font-size: 2rem;
    white-space: nowrap;
  }

  .callOutButton {
    font-size: 18px;
    padding: 10px 24px;
  }

  .projectsSection {
    padding: 32px 36px;
  }

  .projectsTitle {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@media screen and (max-width: 425px) {
  .headerTitleText {
    font-size: 5rem;
    line-height: 5.2rem;
    white-space: nowrap;
  }

  .infoSection {
    padding: 32px 32px;
  }

  .infoText {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .infoText2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sizesSection {
    padding: 48px 24px 24px 0;
    align-items: flex-start;
    min-height: 50vh;
  }

  .sizesTitle {
    padding: 0 24px;
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .sizesSubTitleMobile {
    padding: 0 24px;
  }

  .comparisonBox {
    width: 300%;
  }

  .comparisonHeading {
    font-size: 2.2rem;
  }

  .comparisonText {
    font-size: 0.8rem;
  }

  .projectsSection {
    padding: 32px 24px;
  }

  .projectsTitle {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
}
