.img {
  width: 100%;
  height: 25vw;
  object-fit: cover;
  opacity: 0.5;
}

.newsTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.newsImg {
  width: 100%;
  height: 40vw;
  object-fit: cover;
}
